import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  users: undefined,
};

export const slice = createSlice({
  name: 'companyUsers',
  initialState,
  reducers: {
    LOAD_USERS: (state, action) => ({
      ...state,
      users: action.payload,
    }),
    ADD_USER: (state, action) => ({
      ...state,
      users: [...state.users, action.payload],
    }),
    UPDATE_USER: (state, action) => ({
      ...state,
      users: state.users.map((user) =>
        user.id === action.payload.id ? action.payload : user,
      ),
    }),
    REMOVE_USER: (state, action) => ({
      ...state,
      users: state.users.filter((user) => user.id !== action.payload),
    }),
  },
});

export const { LOAD_USERS, ADD_USER, UPDATE_USER, REMOVE_USER } = slice.actions;

export default slice.reducer;
