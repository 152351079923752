import { createInstance } from 'i18next';
import { initReactI18next } from 'react-i18next';

import assortmentEn from '../../locales/en/assortment.json';
import buyingEn from '../../locales/en/buying.json';
import commonEn from '../../locales/en/common.json';
import insightsEn from '../../locales/en/insights.json';
import rebalancingEn from '../../locales/en/rebalancing.json';
import reorderEn from '../../locales/en/reorder.json';
import replenishmentEn from '../../locales/en/replenishment.json';
import { addFormatters } from '../utils/formatters';

const instance = createInstance({
  debug: process.env.NODE_ENV === 'development',
  fallbackLng: 'en',
  ns: [],
  resources: {
    en: {
      common: commonEn,
      reorder: reorderEn,
      replenishment: replenishmentEn,
      rebalancing: rebalancingEn,
      buying: buyingEn,
      insights: insightsEn,
      assortment: assortmentEn,
    },
  },
});

instance.use(initReactI18next).init();

addFormatters(instance);

export default instance;
