import {
  BrandPage,
  CountryPage,
  DepartmentPage,
  EventPage,
  ListBrandsFilters,
  type ListCountriesFilters,
  type ListDepartmentsFilters,
  ListEventsFilters,
  ListLocationsFilters,
  ListLocationTypesFilters,
  ListProductsFilters,
  ListRegionsFilters,
  ListSeasonsFilters,
  ListSubDepartmentsFilters,
  LocationPage,
  LocationTypePage,
  ProductPage,
  RegionPage,
  SeasonPage,
  SubDepartmentPage,
} from '@autone/openapi-core';
import { customBaseQuery, encodeUrl, isFeatureSwitchEnabled } from '@autone/ui';
import { createApi } from '@reduxjs/toolkit/query/react';

// Define a service using the base core URL and expected endpoints
export const coreApi = createApi({
  reducerPath: 'coreApi',
  baseQuery: async (args, api, extraOptions) => {
    // custom query adds auth headers and error handling
    const isV2Enabled = await isFeatureSwitchEnabled('core-api-v2');
    const uri = isV2Enabled ? 'v2/core' : 'core';
    return customBaseQuery(args, api, extraOptions, uri);
  },
  endpoints: (builder) => ({
    getCoreDepartmentsPaged: builder.query<
      DepartmentPage,
      {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListDepartmentsFilters;
      }
    >({
      query: ({
        sortBy,
        cursor,
        limit,
        filters,
      }: {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListDepartmentsFilters;
      }) => ({
        url: encodeUrl({
          url: `/products/departments?limit={limit}&sort_by={sortBy}${
            cursor ? `&cursor={cursor}` : ``
          }`,
          variables: {
            sortBy,
            limit: `${limit}`,
            cursor: cursor || '',
          },
        }),
        method: 'POST',
        body: {
          filters,
        },
      }),
    }),
    getCoreProductsPaged: builder.query<
      ProductPage,
      {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListProductsFilters;
      }
    >({
      query: ({
        sortBy,
        cursor,
        limit,
        filters,
      }: {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListProductsFilters;
      }) => ({
        url: encodeUrl({
          url: `/products?limit={limit}&sort_by={sortBy}${
            cursor ? `&cursor={cursor}` : ``
          }`,
          variables: {
            sortBy,
            limit: `${limit}`,
            cursor: cursor || '',
          },
        }),
        method: 'POST',
        body: {
          filters,
        },
      }),
    }),
    getCoreSeasonsPaged: builder.query<
      SeasonPage,
      {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListSeasonsFilters;
      }
    >({
      query: ({
        sortBy,
        cursor,
        limit,
        filters,
      }: {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListSeasonsFilters;
      }) => ({
        url: encodeUrl({
          url: `/products/seasons?limit={limit}&sort_by={sortBy}${
            cursor ? `&cursor={cursor}` : ``
          }`,
          variables: {
            sortBy,
            limit: `${limit}`,
            cursor: cursor || '',
          },
        }),
        method: 'POST',
        body: {
          filters,
        },
      }),
    }),
    getCoreEventsPaged: builder.query<
      EventPage,
      {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListEventsFilters;
      }
    >({
      query: ({
        sortBy,
        cursor,
        limit,
        filters,
      }: {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListEventsFilters;
      }) => ({
        url: encodeUrl({
          url: `/products/events?limit={limit}&sort_by={sortBy}${
            cursor ? `&cursor={cursor}` : ``
          }`,
          variables: {
            sortBy,
            limit: `${limit}`,
            cursor: cursor || '',
          },
        }),
        method: 'POST',
        body: {
          filters,
        },
      }),
    }),
    getCoreBrandsPaged: builder.query<
      BrandPage,
      {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListBrandsFilters;
      }
    >({
      query: ({
        sortBy,
        cursor,
        limit,
        filters,
      }: {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListBrandsFilters;
      }) => ({
        url: encodeUrl({
          url: `/products/brands?limit={limit}&sort_by={sortBy}${
            cursor ? `&cursor={cursor}` : ``
          }`,
          variables: {
            sortBy,
            limit: `${limit}`,
            cursor: cursor || '',
          },
        }),
        method: 'POST',
        body: {
          filters,
        },
      }),
    }),
    getCoreSubDepartmentsPaged: builder.query<
      SubDepartmentPage,
      {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListSubDepartmentsFilters;
      }
    >({
      query: ({
        sortBy,
        cursor,
        limit,
        filters,
      }: {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListSubDepartmentsFilters;
      }) => ({
        url: encodeUrl({
          url: `/products/sub-departments?limit={limit}&sort_by={sortBy}${
            cursor ? `&cursor={cursor}` : ``
          }`,
          variables: {
            sortBy,
            limit: `${limit}`,
            cursor: cursor || '',
          },
        }),
        method: 'POST',
        body: {
          filters,
        },
      }),
    }),
    getRegionOptionsPaged: builder.query<
      RegionPage,
      {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListRegionsFilters;
      }
    >({
      query: ({
        sortBy,
        cursor,
        limit,
        filters,
      }: {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListRegionsFilters;
      }) => ({
        url: encodeUrl({
          url: `/locations/regions?limit={limit}&sort_by={sortBy}${
            cursor ? `&cursor={cursor}` : ``
          }`,
          variables: {
            sortBy,
            limit: `${limit}`,
            cursor: cursor || '',
          },
        }),
        method: 'POST',
        body: {
          filters,
        },
      }),
    }),
    getLocationTypeOptionsPaged: builder.query<
      LocationTypePage,
      {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListLocationTypesFilters;
      }
    >({
      query: ({
        sortBy,
        cursor,
        limit,
        filters,
      }: {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListLocationTypesFilters;
      }) => ({
        url: encodeUrl({
          url: `/locations/location-types?limit={limit}&sort_by={sortBy}${
            cursor ? `&cursor={cursor}` : ``
          }`,
          variables: {
            sortBy,
            limit: `${limit}`,
            cursor: cursor || '',
          },
        }),
        method: 'POST',
        body: {
          filters,
        },
      }),
    }),
    getLocationOptionsPaged: builder.query<
      LocationPage,
      {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListLocationsFilters;
      }
    >({
      query: ({
        sortBy,
        cursor,
        limit,
        filters,
      }: {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListLocationsFilters;
      }) => ({
        url: encodeUrl({
          url: `/locations?limit={limit}&sort_by={sortBy}${
            cursor ? `&cursor={cursor}` : ``
          }`,
          variables: {
            sortBy,
            limit: `${limit}`,
            cursor: cursor || '',
          },
        }),
        method: 'POST',
        body: {
          filters,
        },
      }),
    }),
    getCountryOptionsPaged: builder.query<
      CountryPage,
      {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListCountriesFilters;
      }
    >({
      query: ({
        sortBy,
        cursor,
        limit,
        filters,
      }: {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListCountriesFilters;
      }) => ({
        url: encodeUrl({
          url: `/locations/countries?limit={limit}&sort_by={sortBy}${
            cursor ? `&cursor={cursor}` : ``
          }`,
          variables: {
            sortBy,
            limit: `${limit}`,
            cursor: cursor || '',
          },
        }),
        method: 'POST',
        body: {
          filters,
        },
      }),
    }),
  }),
});

export const {
  useGetCoreDepartmentsPagedQuery,
  useGetCoreSeasonsPagedQuery,
  useGetCoreEventsPagedQuery,
  useGetCoreBrandsPagedQuery,
  useGetCoreSubDepartmentsPagedQuery,
  useGetRegionOptionsPagedQuery,
  useGetLocationTypeOptionsPagedQuery,
  useGetLocationOptionsPagedQuery,
  useGetCountryOptionsPagedQuery,
  useGetCoreProductsPagedQuery,
} = coreApi;
