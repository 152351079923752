// @ts-nocheck
import { userApi, userReducer } from '@autone/ui';
import { configureStore } from '@reduxjs/toolkit';

import companyUsersReducer from './companyUsers';
import moduleReducer from './modules';
import { coreApi, hubApi, parametersApi } from './services';

export const store = configureStore({
  reducer: {
    [hubApi.reducerPath]: hubApi.reducer,
    [parametersApi.reducerPath]: parametersApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [coreApi.reducerPath]: coreApi.reducer,
    user: userReducer,
    modules: moduleReducer,
    companyUsers: companyUsersReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(userApi.middleware)
      .concat(parametersApi.middleware)
      .concat(hubApi.middleware)
      .concat(coreApi.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
