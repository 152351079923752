import {
  DashboardSidebar,
  LanguageSelectionPopover,
  MenuIconButton,
  SideBarContent,
  withLanguagePreferenceFeatureFlag,
} from '@autone/ui';
import { experimentalStyled as styled } from '@mui/material/styles';
import { PropsWithChildren, useState } from 'react';

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  [theme.breakpoints.up('lg')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const LanguageSelectionRenderer = withLanguagePreferenceFeatureFlag(
  LanguageSelectionPopover,
  'hub',
);

export default function DashboardLayout({ children }: PropsWithChildren) {
  const [open, setOpen] = useState(false);

  return (
    <RootStyle>
      <MenuIconButton setOpen={setOpen} />
      <DashboardSidebar
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
      >
        <SideBarContent
          renderItems={(isCollapse) => (
            <LanguageSelectionRenderer isCollapse={isCollapse} />
          )}
        />
      </DashboardSidebar>
      <MainStyle>{children}</MainStyle>
    </RootStyle>
  );
}
