import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  modules: undefined,
};

export const slice = createSlice({
  name: 'modules',
  initialState,
  reducers: {
    LOAD_MODULES: (state, action) => ({
      ...state,
      modules: action.payload,
    }),
    LOAD_ANALYTICS: (state, action) => ({
      ...state,
      analytics: action.payload,
    }),
  },
});

export const { LOAD_MODULES, LOAD_ANALYTICS } = slice.actions;

export default slice.reducer;
