// @ts-nocheck

import { AuthGuard, HUB_PATH_APP } from '@autone/ui';
import { lazy } from 'react';
import { Redirect } from 'react-router-dom';

import DashboardLayout from '../layout';

// ---------------------- App Routes --------------- //

// AuthGuard checks if user has currently logged in or not when a user accesses any given page
const AppRoutes = {
  guard: AuthGuard,
  layout: DashboardLayout,
  routes: [
    {
      exact: true,
      path: HUB_PATH_APP.root,
      component: lazy(() => import('../views/Home')),
    },
    {
      exact: true,
      path: HUB_PATH_APP.settings,
      component: lazy(() => import('../views/Settings')),
    },
    {
      layout: lazy(() => import('../views/Parameters')),
      path: HUB_PATH_APP.parameters,
      routes: [
        {
          exact: true,
          path: HUB_PATH_APP.parameter,
          component: lazy(
            () =>
              import('../features/parameters/parameter-detail/ParameterDetail'),
          ),
        },
      ],
    },
    {
      exact: true,
      path: HUB_PATH_APP.userManagement,
      component: lazy(() => import('../views/UserManagement')),
    },
    {
      exact: true,
      path: '/',
      component: () => <Redirect to={HUB_PATH_APP.root} />,
    },
    // if path is not recognised then redirect back to the homepage
    {
      component: () => <Redirect to={HUB_PATH_APP.root} />,
    },
  ],
};

export default AppRoutes;
